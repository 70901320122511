var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"csn-notification"},[_c('UserNotificationButton',{attrs:{"hasNotifications":_vm.hasNotifications,"listLength":_vm.listLength},on:{"toggle":_vm.toggleMenu}}),(_vm.SHOWS_DESKTOP_NOTIFICATION_MENU)?_c('section',[_c('div',{staticClass:"csn-notification-menu",class:{ 'csn-notification-menu-with-scroll': _vm.hasScroll }},[_c('div',{staticClass:"csn-notification-menu-header"},[_c('p',{staticClass:"csn-notification-menu-title"},[_vm._v(_vm._s(_vm.t('notifications')))]),_c('button',{staticClass:"csn-notification-menu-close-btn",on:{"click":_vm.toggleMenu}},[_c('CloseIcon')],1)]),_c('div',{staticClass:"csn-notification-list"},[(_vm.IS_USER_NOTIFICATION_LIST_PENDING || _vm.isNilList)?_c('Loader'):(_vm.isEmptyList)?_c('span',{staticClass:"csn-notification-empty-list"},[_vm._v(" "+_vm._s(_vm.t('empty_list'))+" ")]):_vm._l((_vm.USER_NOTIFICATION_LIST),function(ref){
          var gameImage = ref.gameImage;
          var name = ref.name;
          var amount = ref.amount;
          var bonusCode = ref.bonusCode;
          var id = ref.id;
          var description = ref.description;
          var freeSpin = ref.freeSpin;
          var percent = ref.percent;
return _c('div',{key:id},[_c('UserNotification',{attrs:{"gameImage":gameImage,"name":name,"amount":amount,"bonusCode":bonusCode,"description":description,"percent":percent,"freeSpin":freeSpin},on:{"toggle":_vm.toggleMenu}})],1)})],2)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }