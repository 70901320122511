<template>
  <div class="csn-notification">
    <UserNotificationButton
      :hasNotifications="hasNotifications"
      :listLength="listLength"
      @toggle="toggleMenu"
    />

    <section v-if="SHOWS_DESKTOP_NOTIFICATION_MENU">
      <div
        class="csn-notification-menu"
        :class="{ 'csn-notification-menu-with-scroll': hasScroll }"
      >
        <div class="csn-notification-menu-header">
          <p class="csn-notification-menu-title">{{ t('notifications') }}</p>
          <button @click="toggleMenu" class="csn-notification-menu-close-btn">
            <CloseIcon />
          </button>
        </div>
        <div class="csn-notification-list">
          <Loader v-if="IS_USER_NOTIFICATION_LIST_PENDING || isNilList" />
          <span v-else-if="isEmptyList" class="csn-notification-empty-list">
            {{ t('empty_list') }}
          </span>
          <div
            v-for="{
              gameImage,
              name,
              amount,
              bonusCode,
              id,
              description,
              freeSpin,
              percent,
            } in USER_NOTIFICATION_LIST"
            v-else
            :key="id"
          >
            <UserNotification
              :gameImage="gameImage"
              :name="name"
              :amount="amount"
              :bonusCode="bonusCode"
              :description="description"
              :percent="percent"
              :freeSpin="freeSpin"
              @toggle="toggleMenu"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import {
  USER_NOTIFICATIONS,
  Module,
  USER_NOTIFICATION_LIST,
  IS_USER_NOTIFICATION_LIST_PENDING,
  SHOWS_DESKTOP_NOTIFICATION_MENU,
  TOGGLE_DESKTOP_NOTIFICATION_MENU,
  SHOWS_USER_NOTIFICATION_SIDE_MENU,
  TOGGLE_USER_NOTIFICATION_SIDE_MENU,
  IS_MOBILE_SCREEN,
} from '@/constants'
import { isEmpty, dest, isNil } from '@/helpers'

export default {
  name: USER_NOTIFICATIONS,
  components: {
    Loader: () => import('@/components/Loader'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    UserNotification: () => import('./components/UserNotification'),
    UserNotificationButton: () => import('./components/UserNotificationButton'),
  },
  computed: {
    ...mapState(Module.USER_NOTIFICATION, [
      USER_NOTIFICATION_LIST,
      IS_USER_NOTIFICATION_LIST_PENDING,
    ]),
    ...mapState(Module.MAIN, [
      SHOWS_DESKTOP_NOTIFICATION_MENU,
      SHOWS_USER_NOTIFICATION_SIDE_MENU,
    ]),
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    t() {
      return this.$createComponentTranslator(USER_NOTIFICATIONS)
    },
    isNilList() {
      return isNil(this.USER_NOTIFICATION_LIST)
    },
    isEmptyList() {
      return isEmpty(this.USER_NOTIFICATION_LIST)
    },
    hasNotifications() {
      return (
        !this.IS_USER_NOTIFICATION_LIST_PENDING &&
        !this.isEmptyList &&
        !this.isNilList
      )
    },
    listLength() {
      return Number(this.USER_NOTIFICATION_LIST?.length)
    },
    hasScroll() {
      return this.listLength > 3
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [
      TOGGLE_DESKTOP_NOTIFICATION_MENU,
      TOGGLE_USER_NOTIFICATION_SIDE_MENU,
    ]),
    toggleMenu() {
      this.isMobileScreen
        ? this.TOGGLE_USER_NOTIFICATION_SIDE_MENU(
            !this.SHOWS_USER_NOTIFICATION_SIDE_MENU,
          )
        : this.TOGGLE_DESKTOP_NOTIFICATION_MENU(
            !this.SHOWS_DESKTOP_NOTIFICATION_MENU,
          )
    },
  },
}
</script>
